<template>
	<v-container fluid tag="section">
		<v-form ref="form" v-model="valid" lazy-validation>
			<ti-card :icon="$icons.xapi_content">
				<template #title><span>{{ isEdit ? 'Edit' : 'New' }}</span> Xapi Content</template>
				<template #title-actions-shown>
					<v-btn color="success" elevation="2" fab small class="mr-4" @click="save">
						<v-icon>{{ $icons.save }}</v-icon>
					</v-btn>
				</template>
				<template #title-actions-hidden>
					<ti-confirm @confirm="destroy" message="Are you sure you want to delete this Xapi Content?" button-text="Delete">
						<v-btn color="error" elevation="2" fab small class="mr-4">
							<v-icon>{{ $icons.delete }}</v-icon>
						</v-btn>
					</ti-confirm>
				</template>
				<ti-form-layout>
					<template #sidebar>
						<v-col cols="12">
							<v-select v-model="xapi_content.brand_id" :items="brands" item-text="name" item-value="id" label="Brand"></v-select>
						</v-col>
						<v-col cols="12">
							<v-file-input v-model="xapi_content.file" label="Xapi zip file"></v-file-input>
							<p v-if="xapi_content.file">Has existing file: {{ !!xapi_content.file }}</p>
						</v-col>
						<v-col cols="12">
							<v-btn text color="primary" :href="`https://s3.eu-west-2.amazonaws.com/target-internet-xapi.com/courses/${xapi_content.id}/story.html`" target="_blank">
								Open course
							</v-btn>
						</v-col>
					</template>
					<template>
						<v-col cols="12">
							<v-text-field v-model="xapi_content.name" :rules="validations.name" label="Name"></v-text-field>
						</v-col>
						<v-col cols="12">
							<v-text-field v-model="xapi_content.description" :rules="validations.description" label="Description"></v-text-field>
						</v-col>

					</template>
				</ti-form-layout>
			</ti-card>

			<ti-card :icon="$icons.courses">
				<template #title>Associated Courses</template>
				<template>
					<v-col cols="12" v-if="xapi_content.courses && xapi_content.courses.length > 0">
						<p>These are all the courses that are using this Xapi content</p>
					</v-col>
					<v-col cols="12" v-if="xapi_content.courses && xapi_content.courses.length > 0">
						<v-simple-table>
							<template v-slot:default>
								<thead>
								<tr>
									<th class="text-left">ID</th>
									<th class="text-left">Title</th>
									<th class="text-left">Brand</th>
								</tr>
								</thead>
								<tbody>
								<tr v-for="course in xapi_content.courses" :key="course.id" @click="$router.push({name: 'CoursesEdit', params:{ id: course.id}})" class="hover-pointer">
									<td>
										{{ course.id }}
									</td>
									<td>
										{{ course.title }}
									</td>
									<td>
										{{ course.brand }}
									</td>
								</tr>
								</tbody>
							</template>
						</v-simple-table>
					</v-col>
					<v-col cols="12" v-if="xapi_content.courses && xapi_content.courses.length < 1">
						<p>This content is not associated with any courses. Go to a
							<router-link :to="{name: 'Courses'}">course</router-link>
							to assign Xapi content.
						</p>
					</v-col>
				</template>

			</ti-card>
		</v-form>
	</v-container>
</template>
<script>

import validationRules from "@/util/validation";
import _forEach from 'lodash/forEach'
import {sync} from "vuex-pathify";
import models from '@/models'

export default {

	name: "XapiContentsEdit",
	props: ['id'],
	computed: {
		isEdit() {
			return this.$route.meta?.edit
		},
		xapi_content: sync('xapi_contents/xapi_content'),
		brands: sync('brands/brands')
	},
	data: () => ({
		valid: false,
		validations: {
			name: validationRules('name', ['required', 'minLength:2']),
			description: validationRules('description', ['required', 'minLength:2']),
		}
	}),
	beforeMount() {
		this.reset()
		this.init()
	},
	methods: {
		reset(){
			this.xapi_content = models.XapiContent()
		},
		init() {
			this.$store.set('brands/getBrands!', null)
			if (this.isEdit) {
				this.$store.set('xapi_contents/getXapi_content!', this.id)
			} else {
				this.xapi_content = models.XapiContent()
			}
		},
		save() {
			if (this.$refs.form.validate()) {
				let form1 = new FormData
				form1.append('xapi_content[name]', this.xapi_content.name)
				form1.append('xapi_content[description]', this.xapi_content.description)
				form1.append('xapi_content[file]', this.xapi_content.file)
				form1.append('xapi_content[brand_id]', this.xapi_content.brand_id)
				if (this.isEdit) {
					this.$api.xapi_contents.update(this.id, form1)
						.then(response => {
							this.$toast.add('Xapi Content updated successfully', 'success')
							this.$route.meta.disableWarn = true;
							this.$router.push({name: 'XapiContents'})
						})
						.catch(error => {
							console.log(error.response);
							this.$toast.add(error.response.statusText, 'error')
						})
				} else {
					this.$api.xapi_contents.create(form1)
						.then(response => {
							this.$toast.add('Xapi Content created successfully', 'success')
							this.$route.meta.disableWarn = true;
							this.$router.push({name: 'XapiContents'})
						})
						.catch(error => {
							console.log(error.response);

							let text = `${error.response.statusText}: ${error.response.data.map((error, index) => index + ' ' + error)}`
							this.$toast.add(text, 'error')
						})
				}

			} else {
				this.$toast.add('Please fix validation errors', 'error')
			}
		},
		destroy() {
			this.$api.xapi_contents.delete(this.id)
				.then(response => {
					this.$toast.add('Xapi Content deleted successfully', 'success')
					this.$route.meta.disableWarn = true;
					this.$router.push({name: 'XapiContents'})
				})
				.catch(error => {
					console.log(error.response);
					let errors = Object.entries(error.response.data).map(([index, value]) => `${index} ${value}`)
					let text = `${error.response.statusText}: ${errors.join(' - ')}`
					this.$toast.add(text, 'error')
				})
		},
	}
}
</script>